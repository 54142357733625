<template>
  <div class="dashcontainer">
    <Header></Header>
    <div class="maincontainer">
      <div class="basenav">
        <button @click="goto('Dashboard2')" class="nextBtn">
          <img
            src="@/assets/images/arrowBack.svg"
            alt=""
            width="24"
            height="24"
          />
        </button>
        <span>Campaign redemption rank</span>
      </div>
      <div class="bodycontainer">
        <div class="headertitle">
          <div class="header d-flex align-items-center">
            <img :src="getImage('dashboard', 'reward')" alt="" />{{
              'Campaign redemption rank'
            }}
          </div>
          <div class="filterinput d-flex align-items-center">
            <a-range-picker
              v-model="date"
              :disabled-date="disabledDate"
              :showToday="false"
              :ranges="ranges"
              @change="onChange"
            >
              <div class="inputdate">
                {{ date ? dateFormat() : 'Select Date' }}
                <img
                  class="calendar"
                  :src="getImage('dashboard', 'calendar')"
                  alt=""
                />
              </div>
            </a-range-picker>
            <a-button class="export ml-3" @click="exportFile">
              <img :src="getImage('dashboard', 'export')" alt="" />Export Excel
              File
            </a-button>
          </div>
        </div>
        <div class="filter mt-3">
          <div class="filter-container">
            <button class="btn-sort" @click="showMainFilter = !showMainFilter">
              Fillter
              <img :src="getImage('dashboard', 'filter')" alt="filter icon" />
            </button>
            <div v-if="showMainFilter" class="main-menu-container">
              <div class="check-section">
                <div>
                  <div
                    v-for="i in filter"
                    :key="i.main"
                    class="main-menu"
                    :class="{ select: selectedMain == i.main }"
                    @click="handleSelectMainFilter(i.main)"
                  >
                    <div class="detail">
                      {{ i.main }}
                      <img
                        :src="require('@/assets/images/navigate_next.png')"
                        alt="next"
                      />
                    </div>
                  </div>
                  <a></a>
                </div>
                <div class="sub-menu" v-if="selectedMain == 'Campaign Type'">
                  <div class="detail-sub">
                    <a-checkbox-group
                      v-model="filterCheck.type"
                      @change="onChangeFilter"
                    >
                      <a-checkbox
                        v-for="e in sublist"
                        :key="e"
                        :value="e"
                        class="option"
                      >
                        {{ e }}
                      </a-checkbox>
                    </a-checkbox-group>
                  </div>
                </div>
                <div v-else class="sub-menu">
                  <div class="detail-sub">
                    <a-checkbox-group
                      v-model="filterCheck.status"
                      @change="onChangeFilter"
                    >
                      <a-checkbox
                        v-for="e in sublist"
                        :key="e"
                        :value="e"
                        class="option"
                      >
                        {{ e }}
                      </a-checkbox>
                    </a-checkbox-group>
                  </div>
                </div>
              </div>
              <div class="btn-container">
                <div @click="resetFilter">
                  {{ lbl['user-permissions-reset'] }}
                </div>
                <button @click="filteredDataList" class="ok-btn">
                  {{ lbl['user-permissions-ok'] }}
                </button>
              </div>
            </div>
          </div>
          <a-dropdown
            :disables="rankList && rankList.length <= 1"
            placement="bottomRight"
          >
            <button class="btn-sort">
              {{ lbl['user-permissions-sortby'] }}
              <img :src="getImage('dashboard', 'sort')" alt="Sort icon" />
            </button>
            <a-menu v-model="sort" slot="overlay" @click="handleSort">
              <a-menu-item key="desc">{{ lbl['order_by_a_z'] }}</a-menu-item>
              <a-menu-item key="asc">{{ lbl['order_by_z_a'] }}</a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
        <div class="rankbody">
          <a-empty v-if="!redemtionRank.length" class="mt-5" />
          <RankItem
            v-else
            v-for="rank in dataList"
            :key="rank.rank"
            :data="rank"
          />
        </div>
        <div class="d-flex justify-content-end mt-4">
          <a-pagination
            v-model="currentPage"
            :default-current="1"
            :total="this.redemtionRank.length"
            @change="onChangePage"
            hideOnSinglePage
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mixin from '@/mixin/Mixin'
import Header from '@/components/Layout/Header'
import moment from 'moment'
import Utility from '../../helper/Utility'
import BzbsDashboard from '../../core/Dashboard/BzbsDashboard'
import RankItem from './components/RankItem'
import XLSX from 'xlsx'
export default {
  components: {
    Header,
    RankItem,
  },
  mixins: [Mixin],
  data() {
    return {
      currentPage: 1,
      redemtionRank: [],
      ranges: Utility.ranges,
      date: [moment().subtract(30, 'days'), moment()],
      data: [],
      dataList: [],
      rankList: [],
      sort: [],
      filter: [
        {
          main: 'Status',
          sub: ['Active', 'Inactive'],
        },
        {
          main: 'Campaign Type',
          sub: ['Draw', 'Free', 'Deal', 'Delivery', 'Interface'],
        },
      ],
      showMainFilter: false,
      selectedMain: 'Status',
      filterCheck: {
        status: [],
        type: [],
      },
    }
  },
  watch: {
    data(val) {
      this.rankList = val.slice(
        (this.currentPage - 1) * 10,
        this.currentPage * 10,
      )
    },
  },
  computed: {
    sublist() {
      var find = this.filter.find(({ main }) => main === this.selectedMain)
      return find.sub
    },
  },
  mounted() {
    let query = this.$route.query.data
    this.date = [moment.unix(query[0]), moment.unix(query[1])]
    console.log(
      '🚀 ~ file: RewardRank.vue:81 ~ mounted ~ this.date:',
      this.date,
    )
    this.getRewardRedemtionRank(this.date).then(list => {
      console.log(list)
      this.getDataList()
    })
  },
  methods: {
    getDataList() {
      this.dataList = this.rankList.slice(
        (this.currentPage - 1) * 10,
        this.currentPage * 10,
      )
    },
    onChangePage(page) {
      this.currentPage = page
      this.dataList = this.data.slice(
        (this.currentPage - 1) * 10,
        this.currentPage * 10,
      )
    },
    onChange() {
      this.getRewardRedemtionRank(this.date).then(() => {
        this.getDataList()
      })
    },
    exportFile() {
      this.getRewardRedemtionRank(this.date).then(data => {
        let result = data.map(item => {
          return {
            'Campaign ID': item.id,
            'Campaign Name': item.text,
            'Campaign Status': item.badge[0],
            'Campaign Types': item.badge[1],
            Redeemed: item.adon[1].value,
            Used: item.adon[2].value,
            Quanlity:
              item.adon[0].value && item.adon[0].value >= 100000000
                ? this.lbl['header-view-message-unlimited']
                : item.adon[0].value,
          }
        })
        const dataWS = XLSX.utils.json_to_sheet(result)
        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, dataWS)
        XLSX.writeFile(
          wb,
          'Campaign_Redemtion_Rank_' + Date.parse(new Date()) + '.xlsx',
        )
      })
    },
    getRewardRedemtionRank(date = [moment().subtract(30, 'days'), moment()]) {
      return new Promise(resolve => {
        BzbsDashboard.getRewardRedemtionRank({
          startdate: date[0].format('YYYY-MM-DD'),
          enddate: date[1].format('YYYY-MM-DD'),
        })
          .then(res => {
            this.redemtionRank = res.data.data
            this.data = []
            this.redemtionRank.forEach((item, index) => {
              this.data.push({
                rank: index + 1,
                img: item.PictureUrl,
                showImg: true,
                badge: [item.Campaignstatus, item.Campaigntypes],
                text: item.Campaignname,
                id: item.Campaignid,
                adon: [
                  {
                    key: 'Quantity',
                    text: this.lbl['dashboard-quanlity'],
                    value: item.Quantity ? item.Quantity : 0,
                  },
                  {
                    key: 'Redeemed',
                    text: this.lbl['dashboard-insight-user-history-redeem-tab'],
                    value: item.Redeemed ? item.Redeemed : 0,
                    subfix: 'times',
                  },
                  {
                    key: 'Used',
                    text: this.lbl['dashboard-redeem-by'],
                    value: item.Used ? item.Used : 0,
                    subfix: this.lbl['permission-type-member'],
                  },
                ],
              })
            })
            this.rankList = this.data
            resolve(this.data)
            this.$forceUpdate()
          })
          .catch(err => {
            console.log(err)
            resolve(false)
          })
      })
    },
    disabledDate(current) {
      return current && current > moment().endOf('day')
    },
    handleSort(e) {
      this.currentPage = 1
      this.sort = [e.key]
      if (this.data && this.data.length > 1) {
        if (e.key == 'asc') {
          this.data.sort((a, b) => (a.text < b.text ? 1 : -1))
        } else {
          this.data.sort((a, b) => (a.text > b.text ? 1 : -1))
        }
        this.data.forEach((item, i) => {
          item.rank = i + 1
        })
        this.rankList = this.data
        this.getDataList()
      }
    },
    handleFilter(e) {
      console.log(e)
      console.log(e.preventDefault())
    },
    handleSelectMainFilter(v) {
      this.selectedMain = v
    },
    onChangeFilter(v) {
      this.currentPage = 1
      if (this.selectedMain == 'Status') {
        this.filterCheck.status = v
      } else if (this.selectedMain == 'Campaign Type') {
        this.filterCheck.type = v
      }
    },
    resetFilter() {
      this.filterCheck.status = []
      this.filterCheck.type = []
      this.currentPage = 1
      this.showMainFilter = false
      this.getRewardRedemtionRank(this.date).then(() => {
        this.getDataList()
      })
    },
    filteredDataList() {
      this.showMainFilter = false
      this.getRewardRedemtionRank(this.date).then(data => {
        if (!this.filterCheck.status.length && !this.filterCheck.type.length) {
          this.rankList = this.data
        } else {
          if (this.filterCheck.status.length) {
            if (this.filterCheck.status.length == 2) {
              this.data = data.filter(i => {
                return i.badge[0] == 'Published' || i.badge[0] !== 'Published'
              })
            } else {
              if (this.filterCheck.status.find(status => status == 'Active')) {
                this.data = data.filter(i => {
                  return i.badge[0] == 'Published'
                })
              } else {
                this.data = data.filter(i => {
                  return i.badge[0] !== 'Published'
                })
              }
            }
          }

          if (this.filterCheck.type.length) {
            var arrList = []
            this.filterCheck.type.forEach(type => {
              var result = this.data.filter(i => {
                return i.badge[1] == type
              })
              arrList = arrList.concat(result)
            })
            this.rankList = arrList
          } else {
            this.rankList = this.data
          }
        }

        this.rankList.forEach((item, i) => {
          item.rank = i + 1
        })
        this.redemtionRank = this.rankList
        this.getDataList()
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.dashcontainer {
  min-height: 100vh;

  .basenav {
    display: flex;
    padding: 18px 16px;
    align-items: center;
    gap: 16px;
    border-radius: 12px;
    background: var(--surface-primary, #fff);
    box-shadow: 0px -1px 0px 0px #f0f0f0 inset;

    button {
      padding: 5px;
      border-radius: 8px;
      border: 1px solid var(--grey-grey-darken-1, #757575);
      background: var(--surface-primary, #fff);
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
        0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    }

    span {
      color: var(--black, #192a3e);
      font-size: 24px;
      font-weight: 400;
      text-transform: capitalize;
    }
  }

  .maincontainer {
    .bodycontainer {
      margin-top: 16px;
      padding: 16px;
      gap: 16px;
      border-radius: 16px;
      background: var(--surface-primary, #fff);

      .headertitle {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .header {
          color: var(--text-light-text-title, var(--text-text-title, #4a4a4a));
          font-size: 16px;
          font-weight: 700;
          line-height: 150%;

          img {
            margin-right: 8px;
            border-radius: 8px;
            padding: 8px;
            background: var(--primary-gold-bzb-gold-9, #fff6e6);
          }
        }

        .export {
          display: flex;
          padding: 8px 16px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-radius: 6px;
          border: 1px solid var(--polar-green-7, #389e0d);
          background: var(--brand-color-white, #fff);

          color: var(--polar-green-7, #389e0d);
          font-size: 16px;
          font-weight: 700;
          line-height: 150%;
        }
      }

      .filter {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: unset;
        button {
          display: flex;
          padding: 8px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-radius: 8px;
          border: 1px solid var(--grey-grey-lighten-2, #e0e0e0);
          background: var(--surface-primary, #fff);
        }
      }
    }
  }
}
</style>
<style lang="scss">
@import '@/style/base/common.scss';

.ant-pagination-item-active,
.ant-pagination-item:focus,
.ant-pagination-item:hover {
  border-color: $primary-hover !important;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  font-size: 10px;

  &:hover {
    color: $primary-hover !important;
    border-color: $primary-hover !important;
  }
}

.ant-calendar-footer-extra {
  .ant-tag {
    color: #fa8c16 !important;
    background: #fff7e6 !important;
    border-color: #ffd591 !important;
  }
}

.ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date,
.ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date {
  background: #fa8c16 !important;
  color: #fff !important;
}

.ant-calendar-range .ant-calendar-in-range-cell::before {
  background: #fff7e6 !important;
}

.ant-calendar-today .ant-calendar-date {
  color: #fa8c16 !important;
  border-color: #fa8c16 !important;
}

.ant-calendar-date:hover {
  background: #fff7e6 !important;
}

.ant-calendar-header a:hover {
  color: #fa8c16 !important;
}

.inputdate {
  display: flex;
  position: relative;
  min-width: 200px;
  height: 40px;
  padding: 5px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 6px;
  border: 1px solid var(--neutral-5, #d9d9d9);
  background: var(--neutral-1, #fff);
  cursor: pointer;

  .calendar {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
}

.dashcontainer .anticon {
  vertical-align: 0;
}
.ant-dropdown-trigger,
.ant-dropdown-open,
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-item-selected > a,
.ant-dropdown-menu-submenu-title-selected > a,
.btn-sort,
.ant-btn:hover,
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-item-selected > a,
.ant-dropdown-menu-submenu-title-selected > a,
.btn-sort,
.ant-btn:hover {
  color: #212529;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover,
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-item-selected > a,
.ant-dropdown-menu-submenu-title-selected > a,
.ant-dropdown-menu-item:hover {
  background-color: #fff7e6;
}
.ant-checkbox-wrapper {
  display: block;
}
.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin: unset;
}
.filter-container {
  position: relative;
  cursor: pointer;
  .main-menu-container {
    position: absolute;
    top: 42px;
    width: max-content;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    background: #fff;
    .check-section {
      display: flex;

      .main-menu {
        padding: 8px;
        border-bottom: 1px solid #e0e0e0;

        .detail {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
      .main-menu:last-child {
        .main-menu {
          border-bottom: unset;
        }
      }
      .sub-menu {
        border-left: 1px solid #e0e0e0;
        .detail-sub {
          .option {
            display: flex;
            align-items: center;
            padding: 8px;
            border-bottom: 1px solid #e0e0e0;
            font-size: 16px;
          }
        }
      }
    }
    .select {
      background-color: #fff7e6;
    }
  }
}
.btn-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 16px 0;
  color: blue;

  .ok-btn {
    align-items: center !important;
    padding: 6px 18px !important;
    border-radius: 4px !important;
    background-color: #ff9800 !important;
    color: #fff !important;
  }
}
.hide-filter {
  display: none;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ff9800 !important;
  border-color: #ff9800 !important;
}
</style>
